.ui.menu {
  background-color: var(--background);
  font-family: var(--font-family);
  border-radius: 0;
  box-shadow: none;
  border: none;
}

.ui.menu .active.item,
.ui.menu .item,
.ui.menu .item:hover,
.ui.menu .active.item:hover,
.ui.vertical.menu .active.item:hover,
.ui.menu .active.item:hover .ui.link.menu .item:hover,
.ui.menu .link.item:hover,
.ui.menu a.item:hover,
.ui.menu a.item:active {
  /* menu item hover */
  color: var(--text);
}

.ui.menu .dropdown.item,
.ui.menu .dropdown.item:hover {
  /* dropdown menu item hover */
  color: var(--text);
}

.ui.simple.active.dropdown:not(:hover) > .menu,
.ui.simple.dropdown:not(:hover) > .menu {
  /* Semantic props to explicitly hide the menu*/
  overflow: hidden;
  top: -9999px !important;
  opacity: 0;
  width: 0;
  height: 0;
}

.ui.simple.dropdown:hover > .menu,
.ui.simple.active.dropdown > .menu {
  /* Semantic props to explicitly show the menu*/
  overflow: visible;
  width: auto;
  height: auto;
  top: 100% !important;
  opacity: 1;
}

.ui.menu .item:before {
  background: unset;
}

.ui.vertical.menu .item:before {
  background: unset;
}
