.ApplicationHeader {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 22px;
  flex-wrap: wrap;
}

.ApplicationHeader.environment-demo {
  margin-bottom: 0;
}

.ApplicationHeader .header {
  font-size: 18px;
  font-weight: 500;
  margin-right: 8px;
}

.ApplicationHeader .header > .demo-description {
  font-size: 14px;
  font-weight: 400;
  margin-top: 6px;
}

.ApplicationHeader .application-open-link.disabled,
.ApplicationHeader .Icon.link.disabled {
  cursor: not-allowed;
}

.ApplicationHeader .actions {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  white-space: nowrap;
  margin-left: 16px;
}

.ApplicationHeader.environment-demo .header {
  width: 650px;
}

.ApplicationHeader.environment-demo .actions {
  display: block;
  position: relative;
  width: 100%;
  min-width: 170px;
  flex: 1;
}

.ApplicationHeader .actions a {
  margin-right: 0;
  align-items: center;
}

.ApplicationHeader .actions .EditIcon {
  align-items: center;
  height: 100%;
}
