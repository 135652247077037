.ApplicationsList .ListSection {
  border: none;
}

.ApplicationsList .ui.horizontal.list > .item {
  font-size: 1rem;
  /* these negative margins will overlap items and also revert order (workaround to z-index issue) */
  margin-right: -0.5rem;
  margin-left: -2.5rem;
}

.ApplicationsList .help-message {
  margin-bottom: 32px;
  line-height: 18.77px;
}

.ApplicationsList .help-message > a {
  text-decoration: underline;
  color: var(--text);
}
