.LoginErrorPage {
  margin: 16px;
  padding: 16px;
}

.LoginErrorPage .verify-email-message {
  height: calc(100vh - 64px);

  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 40px;
}

.LoginErrorPage .verify-email-message .logout-button {
  position: absolute;
  top: 32px;
  right: 32px;
}
