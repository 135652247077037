.UserDangerZone {
  padding-top: 16px;
}

.UserDangerZone h3.ui.header {
  font-weight: 600;
  font-size: 20px;
}

.UserDangerZone .pluggy.card {
  font-size: 14px;
  border-width: 1px;
  border-color: var(--grey-100);
  border-radius: 4px;
  border-style: solid;
  padding: 20px;
}

.UserDangerZone .pluggy.card .ui.divider {
  /* setting width to match parent's width and padding */
  width: calc(100% + 2 * 20px);
  left: 50%;
  right: 50%;
  margin-left: -20px;
  margin-top: 20px;
  margin-bottom: 20px;
  /* using !important to override Divider border color */
  border-color: var(--grey-100) !important;
}

.UserDangerZone .pluggy.card .action-buttons {
  display: flex;
  justify-content: flex-end;
}

.UserDangerZone .pluggy.card > .content {
  padding: 20px;
}

.UserDangerZone .pluggy.card > .content > .header {
  font-size: 18px;
}

.UserDangerZone .widgets-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.UserDangerZone .widgets-section > div {
  flex: 1;
  flex-basis: 350px;
  margin-bottom: 18px;
}

.UserDangerZone .action-buttons .pluggy.button.primary:not(.disabled) {
  background-color: var(--danger);
}

.UserDangerZone .action-buttons .pluggy.button.primary:not(.disabled):hover {
  background-color: var(--danger-hover);
}
