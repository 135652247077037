.ui.form.GoToProductionForm {
  /* note: using !important to override semanticUi form styles */
  margin: 0 !important;
}

.GoToProductionForm .contact-text {
  font-size: 14px;
  margin-bottom: 20px;
  white-space: pre-line;
  /* note: using !important to override semanticUi form styles */
  margin-top: 0 !important;
  /* note: using !important to override semanticUi form styles */
  margin-bottom: 20px !important;
}

.GoToProductionForm .contact-subtitle {
  font-weight: 500;
  font-size: 16px;
  /* note: using !important to override semanticUi form styles */
  margin-bottom: 8px !important;
}
