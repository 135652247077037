.EmptyChartMessage {
  border: 1px dashed var(--grey-400);
  border-radius: 4px;
  /* height set to match the chart */
  height: 222px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.EmptyChartMessage .empty-chart-message-content {
  text-align: center;
  font-size: 14px;
}
