#root .ui.divider {
  border-color: var(--divider-color);
  border-top: none;
}

#root .ui.divider.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

#root .ui.divider.fit {
  width: 100%;
}
