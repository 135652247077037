.TeamSubscriptionSection {
  display: flex;
  gap: 16px;
  flex-direction: row;

  margin-bottom: 29px;
}

.TeamSubscriptionSection > div {
  flex: 1;
}

@media only screen and (max-width: 768px) {
  .TeamSubscriptionSection {
    flex-direction: column;
  }
}
