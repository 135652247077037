.Table {
  width: 100%;
  max-width: 1240px;
  margin: auto;
  border: 1px solid var(--table-border-color);
  border-radius: 4px;
  border-spacing: 0;
}

.Table td,
.Table th {
  padding: 16px;
}

.Table .headers-container {
  background-color: var(--table-headers-background-color);
}

.Table .headers-container .table-header,
.Table .table-row .table-row-data-mobile .table-row-header {
  font-family: var(--font-family);
  color: var(--table-text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  white-space: nowrap;
}

/* every 2 elements add the style */
.Table .table-row:nth-of-type(2n) {
  background-color: var(--table-secondary-background-color);
  border-top: 1px solid var(--table-border-color);
  border-bottom: 1px solid var(--table-border-color);
}

.Table .table-row .table-row-data {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: var(--table-text-color);
  border-top: 1px solid var(--table-border-color);
}

.Table .table-row .table-row-data-mobile {
  display: none;
}

.Table .skeleton {
  height: 20px;
}
