:root {
  --main-desable: #7e7a77;
  --grey-50: #f5f5f5;
  --grey-100: #e0e0e0;
  --grey-200: #b1b1b1;
  --grey-200-transparent-05: rgba(177, 177, 177, 0.5);
  --grey-300: #a0a0a0;
  --grey-400: #8f8f8f;
  --grey-500: #676767;
  --grey-600: #4d4d4d;
  --grey-700: #3c3c3c;
  --grey-700-transparent-05: rgba(60, 60, 60, 0.5);
  --grey-800: #2b2b2b;
  --grey-900: #121212;
  --grey-900-transparent-08: rgba(159, 133, 133, 0.8);
  --grey-dark: var(--grey-800);
  --grey-medium-dark: #2f2f2f;
  --grey-medium: #b4b4b4;
  --grey-light: #e0e0e0;
  --grey-lighter: #e4e4e4;
  --white: #ffffff;
  --text-on-white: #7e7e7e;
  --white-transparent: rgba(255, 255, 255, 0.87);
  --white-transparent-06: rgba(255, 255, 255, 0.6);
  --white-transparent-clear: rgba(255, 255, 255, 0.08);
  --error: #f04e54;
  --blue-100: #eff6ff;
  --blue-200: #a0c7f9;
  --blue-300: #458eed;
  --blue-400: #1a73e8;
  --blue-500: #1059b8;
  --blue-600: #0c4a9c;
  --green: #50e2c6;
  --teal-500: #3acab1;
  --green-bold: #3acab1;
  --green-ultra: #daf3ed;
  --green-400: #5cb865;
  --green-500: #298f33;
  --red-error: #de2f2f;
  --red-100: #ffdede;
  --red-300: #ff2525;
  --red-400: #d91616;
  --red-500: #a71111;
  --pink-main-ultra: #942334;
  --pink-main-bold: #b34152;
  --pink-principal: #ef294b;
  --pink-300: #e44963;
  --pink-main: var(--pink-300);
  --pink-main-light: #d5818d;
  --pink-main-lighter: #e8bbc2;
  --orange-300: #fd9047;
  --orange-500: #f36e16;
  --orange-warning: #ec8a13;
  --icon-white: #ffffff;
  --icon-default: #292828;
  --development-tag-color: #a82d77;
  --production-tag-color: #7f5acc;
  --pro-subscription-tag-color: var(--green-500);
  --sandbox-subscription-tag-color: #7f5acc;
  --trial-subscription-tag-color: #49a2a8;
}
