.BillingPage .billing-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--billing-container-border-color);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  padding: 32px 24px;
}

.BillingPage .title {
  font-family: var(--header-font-family);
  font-size: 32px;
  font-weight: 400;
  line-height: 30px;
  margin-right: 14px;
  margin-bottom: 20px;
}

.BillingPage .iframe-container {
  width: 100%;
  min-height: 750px;
  max-height: 750px;
  border-radius: 10px;

  position: relative;
  border: none;
}
