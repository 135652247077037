.ApplicationClientKeys {
  margin-bottom: 20px;
}

.ApplicationClientKeys .pluggy.field {
  margin-top: 10px;
}

.ApplicationClientKeys .CopyIcon {
  margin-left: -30px;
  margin-top: 10px;
}

.ApplicationClientKeys .CopyIcon path {
  fill: var(--input-icon);
}

.ApplicationClientKeys .CopyIcon:hover path {
  fill: var(--input-icon-hover);
}
