.ArrowLink {
  margin: auto 0;
}

.ArrowLink .ArrowIcon {
  --icon-active: var(--blue-300);
  --icon-hover: var(--blue-300);
  --icon-disabled: var(--link-disabled-button);
  width: 28px;
  margin-left: 5px;
}

.ArrowLink a {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-right: 10px;
}
