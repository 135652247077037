.ui.popup {
  border: 0;
  border-radius: 4px;

  background-color: var(--tooltip-background-color);
  font-family: var(--font-family);
  font-size: 14px;
  color: var(--text);
  line-height: 16px;

  padding: 4px 8px;

  max-width: 260px;
}

.ui.popup:before {
  display: none;
}
