.TeamBillingSection {
  background: var(--secondary-card-background-color);
  padding-top: 20px;
  padding-right: 12px;
  padding-left: 20px;
  padding-bottom: 20px;
  border-radius: 4px;
}

.TeamBillingSection .heading {
  margin-bottom: 20px;
}

.TeamBillingSection .text {
  margin-bottom: 20px;
}
