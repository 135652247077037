.UserDetailsSection {
  background: var(--secondary-card-background-color);
  padding: 24px;
  margin-bottom: 36px;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
}

.UserDetailsSection .user {
  display: flex;
  flex-basis: 320px;
  align-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.UserDetailsSection > .fields {
  flex: 1;
  margin-top: 8px;
  flex-basis: 300px;
}

.UserDetailsSection .user .user-data h4.ellipsis,
.UserDetailsSection .user .user-data p.ellipsis {
  width: 220px;
  margin: 4px;
}

.UserDetailsSection h4.ellipsis {
  font-weight: 600;
  font-size: 18px;
}

.UserDetailsSection h5.ellipsis {
  font-weight: 400;
  font-size: 18px;
}

.UserDetailsSection .user .header {
  margin-bottom: 8px;
}

.UserDetailsSection .pluggy.field .pluggy.ui.labeled.input,
.UserDetailsSection .pluggy.field .pluggy.ui.labeled.input input,
.UserDetailsSection .pluggy.field .pluggy.ui.labeled.input label,
.UserDetailsSection
  .pluggy.field
  .pluggy.ui.labeled.input
  label
  .floating-label,
.UserDetailsSection .pluggy.field .pluggy.ui.labeled.input input,
.UserDetailsSection
  .pluggy.field.disabled
  .pluggy.ui.labeled.input.filled
  input,
.UserDetailsSection
  .pluggy.field.disabled
  .pluggy.ui.labeled.input.filled
  input
  ~ .floating-label,
.UserDetailsSection
  .pluggy.field.disabled
  .pluggy.ui.labeled.input.filled
  input
  ~ .floating-label {
  background: var(--secondary-card-background-color) !important;
}
