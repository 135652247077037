.ApplicationDangerZone {
  padding-top: 16px;
  width: 100%;
}

.ApplicationDangerZone .pluggy.card {
  font-size: 14px;
  border-width: 1px;
  border-color: var(--danger-zone-card-border-color);
  border-radius: 4px;
  border-style: solid;
  box-shadow: var(--danger-zone-card-shadow);
  padding: 20px;
}

.ApplicationDangerZone .pluggy.card .ui.divider {
  width: calc(100% + 2 * 20px);
  left: 50%;
  right: 50%;
  margin-left: -20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ApplicationDangerZone .pluggy.card .action-buttons {
  display: flex;
  justify-content: flex-end;
}

.ApplicationDangerZone .action-buttons .pluggy.button.primary:not(.disabled) {
  background-color: var(--danger);
}

.ApplicationDangerZone
  .action-buttons
  .pluggy.button.primary:not(.disabled):hover {
  background-color: var(--danger-hover);
}

.ApplicationDangerZone .pluggy.card > .content {
  padding: 20px;
}

.ApplicationDangerZone .pluggy.card > .content > .header {
  font-size: 18px;
}

.ApplicationDangerZone .widgets-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ApplicationDangerZone .widgets-section > div:first-child {
  /* TODO when wrapping columns this margin should be 0 */
  margin-right: 32px;
}

.ApplicationDangerZone .widgets-section > div {
  flex: 1;
  flex-basis: 350px;
  margin-bottom: 18px;
}
