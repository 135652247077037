.ConnectorStatusRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 21px 0 8px;
}

.ConnectorStatusRow .connector-data {
  display: flex;
  align-items: center;
}

.ConnectorStatusRow .connector-data .connector-logo,
.ConnectorStatusRow .connector-data .connector-logo > img {
  width: 32px;
  height: 32px;
  /* prevent from shrink */
  min-width: 32px;
  min-height: 32px;

  border: 1px solid var(--connector-logo-border-color);
  background: var(--connector-logo-background-color);
  color: var(--text-on-white);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.ConnectorStatusRow .connector-data .connector-name {
  margin-left: 20px;
}

.ConnectorStatusRow .tooltip {
  margin-left: 18px;
  display: flex;
}

.ConnectorStatusRow.disabled .connector-data .connector-name {
  color: var(--grey-400);
}

.ConnectorStatusRow.disabled .StatusBar.operational {
  background-color: var(--grey-400);
}

.ConnectorStatusRow.disabled .StatusBarContainer .status-average.operational {
  color: var(--grey-400);
}
