.ConsentCustomization {
  margin-bottom: 32px;
}

.ConsentCustomization .header-container {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ConsentCustomization .header-container .ui.header {
  margin: 0;
}

.ConsentCustomization .header-container .link {
  margin-left: 20px;
  font-size: 14px;
}
