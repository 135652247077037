.StateIndicator .dot {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.StateIndicator .dot.disabled {
  background-color: var(--orange-warning);
}

.StateIndicator .dot.active {
  background-color: var(--green-400);
}
