.Icon.MenuIcon {
  fill: none;
  cursor: pointer;
}

.Icon.MenuIcon path {
  fill: none;
  stroke: var(--icon-active);
}

.Icon.MenuIcon:hover path {
  fill: none;
  stroke: var(--icon-hover);
}
