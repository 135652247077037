@media only screen and (min-width: 645px) {
  .CompanyRolePage .wrapper-container {
    /* padding-top calculated by testing manually, to approximately center the form
     vertically. In case the component is redesigned/reworked, this may need to be calculated manually again */
    padding-top: 30vh;
  }
}

.CompanyRolePage .pluggy.ui.labeled.input .label.floating-label {
  z-index: 0;
}

.CompanyRolePage
  .pluggy.field
  .pluggy.ui.labeled.input
  input:focus
  ~ .floating-label,
.CompanyRolePage
  .pluggy.field
  .pluggy.ui.labeled.input.filled
  input
  ~ .floating-label {
  /* using !important to override semantic style */
  background: var(--background) !important;
}

.CompanyRolePage .ui.header {
  margin-bottom: 4px;
}

.CompanyRolePage p {
  margin-bottom: 32px;
}

.CompanyRolePage .CompanyRoleInput > .field.pluggy.company-role-input {
  margin-bottom: 10px;
}
