.RecentActivity .list-header-container .ui.header:first-child {
  padding: 6.5px;
}

.RecentActivity .ListSection .list-section-items .list-item {
  /* always 1 column */
  flex-basis: 100%;
}

.RecentActivity .activity-item .ui.avatar.image {
  margin-right: 20px;
}

.RecentActivity .activity-item-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.RecentActivity .activity-empty {
  margin-left: 16px;
}
