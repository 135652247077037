.ConnectorsCustomizationForm .connector-type-label {
  font-size: 14px;
  margin-bottom: 12px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.ConnectorsCustomizationForm .connector-type-label .new-connectors-indicator {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--blue-400);
}

.ConnectorsCustomizationForm .Dropdown .dropdown__option label {
  flex: unset;
}

.ConnectorsCustomizationForm .Dropdown .dropdown__option .Tag {
  margin-left: 12px;
}
