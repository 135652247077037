.TermsAndConditionsModal.ui.modal {
  max-width: 660px;
}

.TermsAndConditionsModal.ui.modal > .header {
  /* Using important to override the !important in SemanticUI */
  padding-top: 44px !important;
}

.TermsAndConditionsModal.ui.modal > .Icon.CloseIcon {
  /* Using important to override the !important in SemanticUI */
  top: 54px !important;
}

.TermsAndConditionsModal.ui.modal > .content {
  line-height: 16.42px;
  padding-bottom: 18px;
}

.TermsAndConditionsModal .terms-and-conditions-container {
  position: relative;
}

.TermsAndConditionsModal .terms-and-conditions-content {
  margin-top: 16px;
  max-height: 500px;
  overflow-y: auto;
  padding-bottom: 28px;
}

.TermsAndConditionsModal .terms-and-conditions-content-backdrop {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 46px;
  background-repeat: no-repeat;
  background-image: linear-gradient(
    180deg,
    rgba(18, 18, 18, 0) 0%,
    var(--background) 100%
  );
}

.TermsAndConditionsModal .terms-and-conditions-accept.ui.checkbox {
  margin-top: 32px;
}

.TermsAndConditionsModal .ui.checkbox input.hidden + label,
.TermsAndConditionsModal .ui.checkbox input.hidden:focus + label,
.TermsAndConditionsModal .ui.checkbox input.hidden:active + label {
  font-size: 12px;
}
