.ItemDataContainer {
  padding: 20px 16px;
  border: 1px var(--executions-container-border-color) solid;
  border-radius: 4px;
}

.ItemDataContainer .item-data-title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 24px;
}

.ItemDataContainer .data-container .row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 32px;
}

.ItemDataContainer .data-container .row .column {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

@media screen and (max-width: 800px) {
  .ItemDataContainer .data-container .row .column {
    width: calc(100% / 2 - 64px);
  }
}

@media screen and (max-width: 550px) {
  .ItemDataContainer .data-container .row .column {
    width: 100%;
  }
}
