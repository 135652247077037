.ui.small.modal.transition.visible.active.FreeTrialExpiredModal > .content,
.ui.small.modal.transition.visible.active.FreeTrialExpiredModal .actions {
  /* note: using important to override semantic !important */
  padding: 26px 24px !important;
}

.ui.small.modal.transition.visible.active.FreeTrialExpiredModal
  > .content
  .title {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: left;

  max-width: 80%;
}

.ui.small.modal.transition.visible.active.FreeTrialExpiredModal
  > .content
  .description {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.ui.small.modal.transition.visible.active.FreeTrialExpiredModal
  .actions
  > .button:first-of-type {
  margin-left: 0;
}
