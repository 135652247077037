.ExecutionsDataContainer {
  margin-top: 16px;
  padding: 20px 16px;
  border: 1px var(--executions-container-border-color) solid;
  border-radius: 4px;
}

.ExecutionsDataContainer .executions-title {
  font-family: var(--title-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;

  margin-bottom: 4px;
}

.ExecutionsDataContainer .executions-subtitle {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.ExecutionsDataContainer .DataPlaceholder {
  padding: 100px 16px 60px;
}
