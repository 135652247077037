/* overrides to make plugguai/ui inputs look good on dashboard backgrounds */

.pluggy.field .pluggy.ui.labeled.input ~ .optional-message {
  color: var(--text-tertiary);
}

.pluggy.field .pluggy.ui.labeled.input,
.pluggy.field .pluggy.ui.labeled.input input,
.pluggy.field .pluggy.ui.labeled.input label,
.pluggy.field .pluggy.ui.labeled.input label .floating-label,
.pluggy.field .pluggy.ui.labeled.input label,
.pluggy.field.disabled .pluggy.ui.labeled.input.filled input,
.pluggy.field.disabled .pluggy.ui.labeled.input.filled input ~ .floating-label,
.pluggy.field.disabled .pluggy.ui.labeled.input.filled input:focus,
.pluggy.field.disabled .pluggy.ui.labeled.input input {
  background-color: var(--background) !important;
}

.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled {
  /* override semanticUI Form rule that applies 0.45 opacity on inputs */
  opacity: 1 !important;
}
