.ListSection {
  padding-top: 0;
}

.ListSection .ui.grid {
  margin: 0;
}

.ListSection .pluggy.alert {
  margin-right: 0;
}

.ListSection .list-header-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
}

.ListSection .list-header-container .ui.secondary.button.right {
  margin-left: 24px;
}

.ListSection .list-header-actions {
  display: flex;
  align-content: center;
  align-items: center;
}

.ListSection .list-header-actions > div,
.ListSection .list-header-actions > .button {
  display: inline-block;
}

.ListSection .ui.header {
  margin: 0;
}

.ListSection .list-header-container .ui.button.primary {
  margin: auto;
  margin-right: 0;
}

.ListSection .list {
  width: 100%;
}

.ListSection .list-empty {
  text-align: center;
  width: 100%;
  font-size: 1.2rem;
}

.ListSection .list-empty .ui.button {
  margin-top: 30px;
}

.ListSection .list-section-items {
  width: 100%;
  overflow: auto;
}

.ListSection .ui.grid > div {
  padding-top: 0;
}

.ListSection .ui.grid .list-section-items.row,
.ListSection .list-section-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  padding-bottom: 0;
}

.ListSection .list-section-items .list-item {
  margin-bottom: 24px;
  /* 2 columns */
  width: calc(50% - 16px);
}

@media (max-width: 1000px) {
  .ListSection .list-section-items .list-item {
    /* 1 column */
    width: 100%;
  }
}
