.WebhookEventsPage {
  padding-bottom: 24px;
}

.WebhookEventsPage > .events-container {
  border: 1px solid var(--stats-border-color);
  box-shadow: var(--primary-card-box-shadow);
  padding: 24px 24px 0;
  border-radius: 4px;
}

.WebhookEventsPage > .events-container > .title {
  margin-bottom: 0;
}

.WebhookEventsPage > .events-container > .description {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 20px;
}

.WebhookEventsPage .table-row-data {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.WebhookEventsPage .table-container {
  overflow-x: scroll;
  max-width: 100%;
}
.WebhookEventsPage .no-data,
.WebhookEventsPage .loader-container {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.WebhookEventsPage .Table .column-header {
  display: flex;
  gap: 8px;
}

.WebhookEventsPage .pluggy .DatePicker input {
  background-color: transparent !important;
}

.WebhookEventsPage .Table .table-row.error {
  background-color: var(--webhook-events-table-row-error-background-color);
}
