.ThemeModal.visible.transition.ui.modal.active {
  /* note: using !important in order override a semanticUI !important */
  display: flex !important;
  flex-direction: column;
  max-width: 660px;

  border-radius: 4px;
}

.ThemeModal .subtitle {
  margin-bottom: 12px;

  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.ThemeModal.visible.transition .content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  padding-bottom: 24px;
}

.ThemeModal .image-container {
  height: 312px;
  background-color: var(--grey-800);
  border-radius: 4px;
  padding: 60px;
  /* center items */
  display: flex;
  justify-content: center;
  align-items: center;
}

.ThemeModal .feature-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;

  margin-bottom: 12px;
  margin-top: 16px;
}

.ThemeModal .feature-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;

  margin-bottom: 10px;
}

.ThemeModal .action-container {
  display: flex;
  justify-content: center;

  margin-top: 12px;
}

.ThemeModal .action-container .icon {
  margin: 0 25px;
}
