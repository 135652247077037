.ui.container.Page {
  margin-top: 24px;
  /* main section is always centered */
  margin: auto;
  /* in mobile (<480px main section is full width) */
  width: 100%;
  /* main section never exceeds 1000px */
  max-width: 1000px !important;
  /* all pages have a 28px margin */
  padding-top: 28px;
}

.ui.container.Page .widget-section-loader {
  height: 200px;
  position: relative;
  width: 100%;
}

@media only screen and (min-width: 480px) {
  .ui.container.Page {
    /* when screen is wider than 480 set at least 24px of margin in each side */
    width: calc(100% - 48px);
  }
}
