.ConnectorsStatus {
  background-color: var(--background);
  border: 1px solid var(--stats-border-color);
  box-shadow: var(--primary-card-box-shadow);

  padding-bottom: 16px;
  position: relative;
  max-height: 632px;
  border-radius: 4px;
  align-self: start;
  padding-top: 4px;
  margin-bottom: 16px;
}

.ConnectorsStatus .loader-container {
  height: 500px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.ConnectorsStatus.extra-space {
  padding-bottom: 40px;
}

.ConnectorsStatus::-webkit-scrollbar {
  width: 4px;
}

.ConnectorsStatus .header-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px 24px 0 24px;
}

.ConnectorsStatus .header-container .header-title {
  display: flex;
  align-items: center;
}

.ConnectorsStatus .header-container .header-title h3.ui.header {
  margin-bottom: 4px;
  color: var(--title-text);

  font-family: var(--title-font-familys);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

.ConnectorsStatus .header-container .header-title .tooltip {
  cursor: pointer;
  margin-left: 12px;
}

.ConnectorsStatus .scroll-shadow {
  background: linear-gradient(180deg, transparent 0%, var(--background) 100%);
  position: absolute;
  width: 100%;
  height: 46px;
  bottom: -46px;
  margin-bottom: 46px;
}

.ConnectorsStatus .EmptyChartMessage {
  margin: 23px;
}
