.ApplicationDetails {
  width: 100%;
  padding-bottom: 60px;
}

.ApplicationDetails .pluggy.input,
.ApplicationDetails .pluggy.field {
  background-color: var(--background);
}

.ApplicationDetails .ApplicationHeader {
  margin-bottom: 28px;
  margin-top: 12px;
  height: 32px;
}

.ApplicationDetails .ApplicationHeader > .header {
  font-size: 24px;
  font-weight: 400;
}

.ApplicationDetails .ApplicationHeader .actions {
  justify-content: flex-start;
}

.ApplicationDetails .application-details-general > div:first-child {
  margin-right: 32px;
}

.ApplicationDetails .application-details-general > div {
  flex: 1;
}

.ApplicationDetails .application-details-get-started {
  margin-top: 40px;
}

.ApplicationDetails
  .application-details-get-started
  > .application-details-get-started-cards {
  display: flex;
  flex-basis: auto;
}

.ApplicationDetails
  .application-details-get-started
  > .application-details-get-started-cards {
  display: flex;
}

.ApplicationDetails
  .application-details-get-started
  > .application-details-get-started-cards
  > div:nth-child(odd) {
  margin-right: 15px;
}
