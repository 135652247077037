.Stats {
  background-color: var(--background);
  border-top: none;
  box-shadow: var(--primary-card-box-shadow);
  border-radius: 4px;
  margin-bottom: 24px;
}

.Page .Stats .widget-section-loader {
  /* height to match Stats height and avoid component growing when it loads */
  height: 460px;
}

.Stats .stats-container {
  padding: 0 23px 24px 23px;
  border: 1px solid var(--stats-border-color);
  border-top: none;
}

.Stats .stats-header {
  padding-top: 24px;
  padding: 24px 23px;

  border: 1px solid var(--stats-border-color);
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  background: linear-gradient(
    180deg,
    var(--background) 76%,
    var(--background) 83%,
    transparent 100%
  );
  z-index: 1;
  position: sticky;
  top: 0;
}

.Stats .stats-filters {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.Stats .stats-content .stats-title-container {
  white-space: nowrap;
  margin-top: 5px;
}

.Stats .stats-content .stats-title {
  margin-bottom: 16px;
  font-family: var(--header-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

.Stats .pluggy .DatePicker {
  height: 38px;
}

.Stats .pluggy .DatePicker .react-datepicker {
  box-shadow: var(--date-picker-shadow);
}

/* note: this fixes bug that shows selected dates in aria-live */
/* this doesn't heppen in UI library  */
.DatePicker.pluggy.date-filter
  [aria-live='polite'].react-datepicker__aria-live {
  display: none;
}

.Stats .pluggy .DatePicker input {
  background-color: transparent !important;
}

.Stats .field.pluggy {
  margin-bottom: 0;
}

.Stats .stats-filters {
  gap: 8px;
}

/* set width and right margin for the 4 dropdowns */
.Stats .stats-filters .date-filter,
.Stats .stats-filters .ItemsTypeFilters,
.Stats .stats-filters .ApplicationFilters,
.Stats .stats-filters .ConnectorsFilter {
  width: 232px;
  height: 40px;
}

.Stats .stats-body {
  display: flex;
  gap: 8px;
}

/* when filters don't fit, they're stacked vertically */
@media only screen and (max-width: 1119px) {
  .Stats .stats-filters .DatePicker button.trigger,
  .Stats .stats-filters .date-filter,
  .Stats .stats-filters .ItemsTypeFilters,
  .Stats .stats-filters .ApplicationFilters,
  .Stats .stats-filters .ConnectorsFilter {
    width: 100%;
    margin-right: 0;
    margin-bottom: 8px;
  }
}

.Stats .widget-section-loader,
.Stats .ApplicationEmptyPlaceholder {
  /* Loader/content height should always match */
  min-height: 270px;
  height: 100%;
}
