.EnvironmentTag {
  color: white;
}

.EnvironmentTag.production {
  background-color: var(--production-tag-color);
}

.EnvironmentTag.development {
  background-color: var(--development-tag-color);
}
