.FloatingMenu .DocumentationLinks .link {
  text-align: end;
  color: var(--text);
  padding: 8px;
}

.FloatingMenu .DocumentationLinks hr {
  display: none;
}

.FloatingMenu .DocumentationLinks .link:hover {
  background-color: var(--navbar-mobile-menu-item-hover-background-color);
  text-decoration: none;
}
