:root {
  --spacing-xxs: 0.5rem;
  --spacing-xs: 1rem;
  --spacing-s: 1.5rem;
  --spacing-m: 2rem;
  --spacing-l: 2.5rem;
  --spacing-xl: 3rem;
  --spacing-xxl: 3.5rem;
  --spacing-xxl-2: 4rem;
  --spacing-xxl-3: 4.5rem;
  --spacing-xxl-4: 5rem;
  --spacing-xxl-5: 5.5rem;
  --spacing-xxl-6: 6rem;
  --spacing-xxl-7: 6.5rem;
}
