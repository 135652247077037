.DailyChart {
  width: 100%;
  position: relative;
  border: 1px solid var(--stats-border-color);
  padding: 20px 0;
  border-radius: 4px;
  margin-bottom: 24px;
}

.DailyChart > .title {
  margin-bottom: 16px;
  font-family: var(--header-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin-left: 20px;
}

.DailyChart
  .recharts-default-legend
  .recharts-legend-item
  .recharts-legend-item-text {
  font-size: 13px;
  /* note: using !important in order to override inline style */
  color: var(--text) !important;
  line-height: 16.42px;
  margin-left: 8px;
}

.DailyChart .recharts-legend-item {
  /* note: using !important in order to override inline style */
  margin-right: 24px !important;
}

.DailyChart .recharts-legend-item .recharts-surface {
  /* note: using !important in order to override inline style */
  margin-right: 0px !important;
}

.DailyChart .tooltip-legend {
  display: flex;
  flex-direction: column;
  background-color: var(--background);
  gap: 4px;
  box-shadow: var(--primary-card-box-shadow);
  padding: 12px 0;
}

.DailyChart .tooltip-legend p {
  margin: 0 12px;
}

.DailyChart .EmptyChartMessage {
  margin-left: 20px;
  margin-right: 20px;
}
