.Tag {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding: 4px 8px;
  border-radius: 10px;
  margin-left: 8px;
  margin-right: 8px;
  display: inline;
  color: white;
}

.Tag.info {
  background-color: var(--blue-400);
}

.Tag.success {
  background-color: var(--green-500);
}
