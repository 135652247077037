.ItemExecutionsPage {
  padding-bottom: 32px;
}

.ItemExecutionsPage .search-box {
  margin-bottom: 24px;
}

.ItemExecutionsPage .item-with-executions-container {
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--executions-container-border-color);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  padding: 32px 24px;
}

.ItemExecutionsPage .item-with-executions-container .title {
  font-family: var(--header-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;

  margin-bottom: 8px;
}

.ItemExecutionsPage .item-with-executions-container .subtitle {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 20px;
}
