.ui.oval,
.image.oval {
  width: 32px;
  height: 32px;
  border: solid 1px var(--grey-lighter);
  border-radius: 500rem;
}

.ui.oval.medium,
.ui.oval.large {
  margin-right: 16px;
  vertical-align: top;
}

.ui.oval.medium {
  width: 40px;
  height: 40px;
}

.ui.oval.large {
  width: 72px;
  height: 72px;
}

/* Background color defaults, unless .placeholder or .has-picture are present */
.ui.oval:not(.placeholder):not(.has-picture),
.image.oval:not(.placeholder):not(.has-picture) {
  background-image: linear-gradient(
    to bottom,
    var(--green-ultra),
    var(--icon-white)
  );
}

.ui.oval.medium:not(.placeholder):not(.has-picture) {
  background-image: linear-gradient(
    to bottom,
    var(--pink-main-lighter),
    var(--icon-white)
  );
}

.ui.oval.large:not(.placeholder):not(.has-picture) {
  background-image: linear-gradient(to bottom, #a8f7e9 2.3%, #0c0028 100%);
}

.ui.oval.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.ui.dropdown .menu > .item > .oval {
  display: inline-block;
  vertical-align: top;
  width: 2rem;
  max-height: 2em;
  min-width: 2rem;
  min-height: 2rem;
  margin-top: -0.5em;
  margin-bottom: -0.5em;
}
