.NoWebhooksCreatedSection {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.NoWebhooksCreatedSection .title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.NoWebhooksCreatedSection .description {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 34px;
  width: 400px;
  text-align: center;
}
