.OverviewPage .ui.segment .ui.placeholder,
.OverviewPage .ui.placeholder .image.header:after,
.OverviewPage .ui.placeholder .line,
.OverviewPage .ui.placeholder .line:after,
.OverviewPage .ui.placeholder > :before {
  background-color: #f4f4f4;
}

.OverviewPage .RecentActivity {
  /* the component is in progress, hiding it */
  display: none;
}

.OverviewPage {
  padding-bottom: 48px;
}
