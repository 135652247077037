.ConnectorsStatusList {
  max-height: 555px;
  overflow-y: auto;
  padding-right: 12px;
  margin: 8px 8px 24px 24px;
}

.ConnectorsStatusList .line {
  width: 100%;
  border-bottom: 1px solid var(--connector-row-divider);
  margin: 16px 0;
}

.ConnectorsStatusList h4.ui.header {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;

  color: var(--text-secondary);
  margin-top: 20px !important;
  margin-bottom: 20px;
}
.ConnectorsStatusList::-webkit-scrollbar {
  /* Don't display scrollbar when scroll shadow is present. */
  display: none;
}

.ConnectorsStatusList .status-row:last-child {
  padding-bottom: 20px;
}
