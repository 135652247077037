.TeamMembersEmailsInput .team-role-input,
.TeamMembersEmailsInput .ui.dropdown .menu .item {
  font-size: 14px;
}

.TeamMembersEmailsInput .team-members-emails-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 16px;
  flex-wrap: wrap;
}

.TeamMembersEmailsInput .team-members-emails-header > span {
  line-height: 24px;
  margin: 0;
}

.TeamMembersEmailsInput .team-members-emails-header span,
.TeamMembersEmailsInput .team-members-emails-header button.ui.button.pluggy {
  margin-bottom: 8px;
}

.TeamMembersEmailsInput .add-item-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.TeamMembersEmailsInput .team-role-input {
  flex: 1;
  width: 100%;
  min-width: 200px;
  margin: 0;
}

.TeamMembersEmailsInput .add-item-row > .field.pluggy:first-child {
  width: 100%;
  margin-bottom: 30px;
}

@media only screen and (min-width: 480px) {
  .TeamMembersEmailsInput .add-item-row {
    flex-wrap: nowrap;
  }

  .TeamMembersEmailsInput .team-role-input {
    margin-left: 12px;
  }

  .TeamMembersEmailsInput .add-item-row > .field.pluggy:first-child {
    margin-bottom: 12px;
  }
}

.TeamMembersEmailsInput .Dropdown.pluggy {
  /* width to match biggest element*/
  width: 200px;
}

.TeamMembersEmailsInput .add-item-row .bottom-section {
  display: flex;
  flex-grow: 1;
}

.TeamMembersEmailsInput
  .add-item-row
  .bottom-section
  .field.pluggy:first-child {
  width: 100%;
}

.TeamMembersEmailsInput
  .add-item-row
  .bottom-section
  .Dropdown.pluggy.team-role-input {
  width: 100%;
}

.TeamMembersEmailsInput .DeleteIcon {
  margin-top: 4px;
  width: 22px;
  height: 30px;
  margin-left: 20px;
}

.TeamMembersEmailsInput .team-role-input {
  /* set to prevent semantic UI <Form> from setting the dropdown
  arrow height by setting a font size.  */
  font-size: 0;
}

.TeamMembersEmailsInput .field.pluggy {
  margin-bottom: 0px;
  height: 40px;
}
