.Icon.ArrowIcon path {
  fill: none;
  stroke: var(--icon-active);
}

.Icon.ArrowIcon:hover path {
  fill: none;
  stroke: var(--icon-hover);
}

.Icon.ArrowIcon.disabled path {
  fill: none;
  stroke: var(--icon-disabled);
}