.LegendItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 240px;
  gap: 8px;
}

.LegendItem .circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.LegendItem .percentage {
  margin-left: auto;
}
