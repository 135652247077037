.GeneralCustomizationForm {
  background: var(--background);
  flex-shrink: 2;
}

.CustomizationPage .GeneralCustomizationForm .ui.header {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--font-size);
  line-height: 18px;
  margin-bottom: 16px;
  margin-top: 20px;
  padding: 0;
}

.CustomizationPage .GeneralCustomizationForm .ui.header:first-child {
  margin-top: 0;
}

.GeneralCustomizationForm .logoImage {
  display: flex;
  margin-top: 20px;
  width: 100%;
}
.GeneralCustomizationForm .logoImage {
  margin-bottom: 28px;
}

.GeneralCustomizationForm .logoImage .FilePickerArea {
  width: 100%;
}

.GeneralCustomizationForm .logoImage > .IsDisabledPopup.field {
  flex: 1;
  display: flex;
  justify-content: center;
}

.GeneralCustomizationForm .logoImage .imageUrl {
  margin-top: 8px;
}

.GeneralCustomizationForm .logoImage .imageUrl .title {
  margin-bottom: 6px;
  font-weight: 500;
}

.GeneralCustomizationForm .logoImage .ui.input.pluggy {
  margin-top: 14px;
  margin-bottom: 14px;
}

.GeneralCustomizationForm .logoImage .field.pluggy {
  width: 429px;
}

.GeneralCustomizationForm .logoImage .input.pluggy.floating-label-input {
  margin-top: 10px;
}

.GeneralCustomizationForm .primaryColor .field.pluggy,
.GeneralCustomizationForm .buttons .field.pluggy {
  width: 187px;
}

.GeneralCustomizationForm .button-texts {
  display: flex;
  gap: 32px;
  margin-top: 20px;
}

/* TODO this should not be needed, should be already the default? or maybe missing in the field parent? */
.GeneralCustomizationForm .ui.form,
.GeneralCustomizationForm .ui.form .field,
.GeneralCustomizationForm .ui.form .field.pluggy,
.GeneralCustomizationForm .ui.form .field.pluggy .pluggy.ui.input {
  background: inherit;
}

.pluggy.field.disabled,
.pluggy.field.disabled input {
  pointer-events: all !important;
}
