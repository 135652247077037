.DocumentationLinks {
  display: flex;
  flex-direction: column;
  background-color: var(--secondary-card-background-color);
  border-radius: 4px;
  padding: 2px 6px;
}

.DocumentationLinks .link {
  padding: 2px 6px;
  font-size: 14px;
  margin: 4px 0;
  border-radius: 4px;
}

.DocumentationLinks hr {
  background-color: transparent;
  border: none;
  border-top: 1px solid var(--navbar-documentation-links-divider);
  margin: 0;
}
