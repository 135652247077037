.StatusBarContainer {
  display: flex;
  align-items: center;
}

.StatusBarContainer .StatusBar {
  margin-left: 4px;
}

.StatusBarContainer .status-average {
  /* set a width to prevent from having different width between numbers */
  width: 40px;
  margin-left: 16px;

  display: flex;
  justify-content: center;
}

.StatusBarContainer .status-average.operational {
  color: var(--status-bar-operational-color);
}

.StatusBarContainer .status-average.degradedPerformance {
  color: var(--status-bar-degraded-performance-color);
}

.StatusBarContainer .status-average.outage {
  color: var(--status-bar-outage-performance-color);
}

.StatusBarContainer .status-average.non-executions {
  color: var(--text);
}
