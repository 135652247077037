.AccordionItem {
  border: 1px solid var(--accordion-item-border-color);
  border-radius: 4px;
  margin-bottom: 20px;
}

.AccordionItem:hover {
  border-color: var(--accordion-item-hover-border-color);
}

.AccordionItem.active {
  border: 1px solid var(--accordion-item-active-border-color);
}

.AccordionItem > .content {
  display: none;
  padding: 0 20px 16px 20px;
}

.AccordionItem.active > .content {
  display: block;
}

.AccordionItem .header {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 20px 24px 20px 16px;
}

.AccordionItem .header h3 {
  flex: 1;
  font-size: 18px;
  margin-bottom: 0;
}

.AccordionItem > button {
  background-color: inherit;
  border: unset;
  outline: unset;
  display: inherit;
  width: 100%;
  text-align: inherit;
}
