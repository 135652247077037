.OnboardingPage .main-container .TeamMembersEmailsInput {
  margin-bottom: 24px;
}

.OnboardingPage .Dropdown.pluggy.company-role-input > div {
  /* specifying z index to avoid dropdown overlapping with background */
  z-index: 3;
}

.OnboardingPage .Dropdown.pluggy.platforms > div {
  /* specifying z index to avoid dropdown overlapping with background */
  z-index: 2;
}

.OnboardingPage .field.pluggy.optional-input {
  /* make the margin smaller in optional input to avoid extra space between inputs */
  margin-bottom: 2px;
}

@media only screen and (min-width: 645px) {
  .OnboardingPage .wrapper-container {
    /* padding-top calculated by testing manually, to approximately center the form
     vertically, but let it grow to the bottom if more emails are added.
     In case the component is redesigned/reworked, this may need to be calculated manually again */
    padding-top: 14vh;
  }
}
