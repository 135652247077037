.ui.header {
  color: var(--text);
}

.ui.header:first-child {
  margin-top: 0;
}

.ui.card > .content > .header,
.ui.cards > .card > .content > .header {
  color: var(--text);
}

h1.ui.header,
h2.ui.header,
h3.ui.header,
h4.ui.header,
h5.ui.header,
h6.ui.header {
  font-family: var(--header-font-family);
  color: var(--title-text);
}

h1,
h2,
h3,
h4,
h1.ui.header,
h2.ui.header,
h3.ui.header,
h4.ui.header {
  font-weight: 400;
}

h5,
h5.ui.header {
  font-weight: 500;
}

h6,
h6.ui.header {
  font-weight: 400;
}

h2,
h2.ui.header,
.ui.modal > .header {
  font-size: 24px;
  line-height: 36px;
}

h3.ui.header,
h3 {
  font-size: 20px;
  line-height: 30px;
}

h4.ui.header,
h4 {
  font-size: 18px;
}

h5.ui.header,
h5 {
  font-size: 18px;
  line-height: 21px;
}

h6.ui.header,
h6 {
  font-size: 16px;
}

h6 {
  margin-block-start: 0;
  margin-block-end: 0;
}

.ui.modal > .header:not(.ui),
.ui.modal > .header {
  font-family: var(--header-font-family);
  font-weight: var(--font-weight);
}

.ui.modal > .header:not(.ui) > p,
.ui.modal > .header > p {
  font-family: var(--font-family);
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 8px;
}
