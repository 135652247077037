.TeamForm {
}

.TeamForm .pluggy.field .pluggy.ui.labeled.input {
  background-color: var(--background);
}

.TeamForm .company-role-input,
.TeamForm .platforms {
  /* set to prevent semantic UI <Form> from setting the dropdown
  arrow height by setting a font size.  */
  font-size: 0;
}
