:root {
  /* fonts */
  --font-family: Work Sans, -apple-system, system-ui, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  --font-monospace: 'Inconsolata', 'Consolas', 'Droid Sans Mono', 'Monaco',
    'Courier New', monospace;

  --header-font-family: Poppins, Work Sans, -apple-system, system-ui,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  --font-size: 16px;
  --font-weight: normal;
  --font-stretch: normal;
  --font-style: normal;
  --line-height: normal;
  --letter-spacing: normal;
}

body {
  font-family: var(--font-family);
  font-size: var(--font-size);
  background: var(--background);
  color: var(--text);

  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--header-font-family);
  color: var(--title-text);
}

p {
  color: var(--text);
}

a,
.link {
  color: var(--text-link);
  cursor: pointer;
  text-decoration: none;
}
a:hover,
.link:hover {
  color: var(--text-link);
  text-decoration: var(--text-link-hover);
}

.text {
  color: var(--text);
}

.text:hover {
  color: var(--text);
}

.secondary-text {
  color: var(--secondary-text);
}

.pointer {
  cursor: pointer;
}

.ellipsis {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* background color and color transition */
* {
  transition: background-color 0.2 ease-in-out, color 0.2 ease-in-out;
}
