.StatusBar {
  display: block;
  position: relative;
  width: 4px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
}

.StatusBar.operational {
  background-color: var(--status-bar-operational-color);
}

.StatusBar.degradedPerformance {
  background-color: var(--status-bar-degraded-performance-color);
}

.StatusBar.outage {
  background-color: var(--status-bar-outage-performance-color);
}
