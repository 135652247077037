.Navbar {
  position: relative;
  flex-direction: column;
}

.Navbar.ui.menu {
  border: 0;
  border-bottom: solid 1px var(--navbar-bottom-border-color);
  min-height: 96px;
  margin-bottom: 0;
  margin-top: 0;
}
.Navbar .ui.top-row.menu {
  align-items: center;
}

.Navbar.ui.menu .ui.image.logo {
  min-width: 46px;
}

.Navbar.ui.menu .ui.image.logo > img {
  height: 29px;
  margin-right: 25px;
}

@media only screen and (max-width: 1440px) {
  /* when navbar elements start overlapping
  adapt design. */
  .Navbar.ui.menu .ui.image.logo > img {
    height: 24px;
    margin-right: 10px;
  }

  .Navbar.ui.menu .ui.image.logo {
    min-width: 36px;
  }

  .Navbar {
    padding-top: 8px;
  }

  .Navbar.ui.menu .current-profile {
    padding-left: 3px;
    padding-right: 0;
  }

  .Navbar.ui.menu .current-profile svg {
    margin-left: 0px;
  }

  .Navbar.ui.menu .oval.ui.avatar.inline.image {
    width: 24px;
    height: 24px;
  }

  .Navbar.ui.menu .ui.item.current-profile .profile-name.ellipsis {
    font-size: 14px;
  }

  .Navbar.ui.menu .ui.item.current-profile {
    width: 200px;
  }

  .Navbar.ui .transition,
  .Navbar.ui.menu .ui.dropdown .menu.visible.transition,
  .Navbar .ui.simple.dropdown:hover > .menu,
  .Navbar .ui.simple.active.dropdown > .menu {
    width: 256px !important;
  }
}

.Navbar.ui.menu .logo-item.item:before {
  /* vertical divider next to logo image */
  height: 36px;
  top: 50%;
  transform: translateY(-50%);
  background: var(--navbar-logo-image-vertical-divider-color);
}

.Navbar.ui.menu a.active.item,
.Navbar.ui.menu a.item:hover {
  background-color: var(--navbar-active-item-background-color);
  color: var(--text-color);
}

.Navbar.ui.menu a.item {
  padding: 8px;
  margin: 30px 7px;
  border-radius: 4px;
  color: var(--text-color);
}

.Navbar.ui.menu .item.logo-item {
  padding: 0;
}

.Navbar.ui.menu .right.menu,
.Navbar.ui.menu .left.menu,
.Navbar.ui.menu .ui.simple.visible.dropdown > .menu {
  display: flex;
  flex: 1;
}

.Navbar.ui.menu .ui.dropdown .menu.visible.transition {
  /* using !important to override Semantic 'display:block !important' */
  display: flex !important;
}

.Navbar.ui.menu .right.menu {
  justify-content: flex-end;
}

.Navbar.ui.menu .ui.dropdown .menu > .header {
  text-transform: none;
  font-size: 14px;
  font-weight: normal;
  padding-left: 16px;
  margin-top: 12px;
}

.Navbar.ui.menu .ui.item.current-profile {
  margin-left: 8px;
  margin-right: 10px;
}

.Navbar.ui.menu .ui.item.current-profile.closed .left.menu.transition {
  display: none !important;
}

.Navbar.ui.menu .ui.item.current-profile .profile-name.ellipsis {
  max-width: 120px;
}

.Navbar.ui.menu .oval.ui.avatar.inline.image {
  margin: 0 8px 0 0;
}

.Navbar.ui.menu .ui.item.profile-options {
  margin-right: 0;
  padding-right: 0;
  padding-left: 0;
  margin-left: 20px;
}

.Navbar.ui.menu .ui.dropdown .menu > .item.profile-option,
.Navbar.ui.menu .ui.dropdown .menu > .item.profile-option:not(.filtered) {
  /* Using important to override the !important in SemanticUI */
  padding: 12px 8px !important;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  border-radius: 4px;
  margin: 0 8px;
}

.Navbar.ui.menu .profile-option .team-name.ellipsis {
  width: 126px;
  flex: 1;
}

.Navbar.ui.menu .ui.dropdown .menu > .item.create-team {
  /* Using important to override the !important in SemanticUI */
  display: flex !important;
  padding-right: 16px !important;
  margin: 0 8px 8px 8px !important;
  border-radius: 4px;
}

.Navbar.ui.menu .ui.dropdown .menu > .item.create-team .PlusIcon {
  margin-left: auto;
}

.Navbar.ui.menu .feedback-button-trigger.item {
  /* Using important to override the !important in SemanticUI */
  margin: auto 0 auto 12px !important;
}

.Navbar.ui.menu .ui.item.profile-options .menu.account-menu {
  /* Using important to override the !important in SemanticUI */
  padding: 8px !important;
  /* bring closer menu to the trigger to improve ux hover */
  top: 85% !important;
  background-color: var(--navbar-profile-options-background-color);
}

.Navbar.ui.menu .ui.item.profile-options .account-menu .item {
  /* Using important to override the !important in SemanticUI */
  color: var(--navbar-profile-options-item-background-color) !important;
  font-size: 14px !important;
}

.Navbar.ui.menu .ui.item.profile-options .account-menu .item:hover {
  /* Using important to override the !important in SemanticUI */
  background-color: var(
    --navbar-profile-options-item-hover-background-color
  ) !important;
  border-radius: 5px;
}

.Navbar.ui.menu
  .ui.item.profile-options
  .account-menu
  a.item.status-page-link:hover {
  border: none;
}

.Navbar .MenuIcon {
  margin: auto;
}

.Navbar .bottom-nav {
  overflow: scroll;
  white-space: nowrap;
  display: flex;
}

.Navbar .bottom-nav .item {
  font-size: 14px;
}

/* hide the scrollbar in the bottom nav*/
.Navbar .bottom-nav::-webkit-scrollbar {
  display: none;
}

.Navbar .bottom-nav .item {
  margin: 4px 14px 18px 0px !important;
}

.Navbar .MobileMenu {
  position: absolute;
  right: 16px;
  top: 55px;
  width: 200px !important;
  z-index: 2;
  background-color: var(--secondary-card-background-color) !important;
  border-radius: 4px !important;
  padding: 8px;
}

.Navbar .MobileMenu .item {
  margin: 0 !important;
  font-size: 14px;
  text-align: right;
  height: 40px !important;
  padding: auto;
  color: var(--text) !important;
  border-radius: 4px !important;
  line-height: 23px !important;
}

.Navbar .MobileMenu .item .documentation svg {
  width: 100px;
}

.Navbar .MobileMenu .item:hover {
  background-color: var(
    --navbar-mobile-menu-item-hover-background-color
  ) !important;
}

.Navbar .MobileMenu .item .ArrowLink a {
  justify-content: end;
  padding-bottom: 4px !important;
  margin: 0 !important;
  padding-top: 0 !important;
  padding-left: 0 !important;
}

.Navbar .floating-menu-backdrop {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1;
}
