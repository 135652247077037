.DataPlaceholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 40px 16px 20px;
}

.DataPlaceholder .message {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;

  margin-top: 16px;
}
