.ApplicationWebhooks > .header-container {
  display: flex;
  gap: 20px;
  margin-bottom: 16px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.ApplicationWebhooks > .header-container .description {
  font-weight: 400;
  font-size: 14px;
}

.ApplicationWebhooks > .header-container .ui.button.add-webhook {
  display: flex;
  white-space: nowrap;
}

.ApplicationWebhooks > .header-container .ui.button.add-webhook > .content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ApplicationWebhooks > .header-container .ui.button.add-webhook .PlusIcon path,
.ApplicationWebhooks
  > .header-container
  .ui.button.add-webhook
  .PlusIcon
  path:hover {
  fill: var(--primary);
}

.ApplicationWebhooks
  > .header-container
  .button.ui.disabled.pluggy.add-webhook
  .PlusIcon
  path,
.ApplicationWebhooks
  > .header-container
  .ui.button.add-webhook
  .PlusIcon
  path:hover {
  fill: var(--icon-disabled);
}

.ApplicationWebhooks
  > .header-container
  .button.ui.disabled.pluggy.add-webhook {
  cursor: not-allowed;
}

.ApplicationWebhooks .disabled-application-placeholder {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  border-radius: 4px;
  /* custom dashed border - generated by: https://kovart.github.io/dashed-border-generator/ */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23A0A0A0FF' stroke-width='1' stroke-dasharray='4 3' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  background-color: var(--primary-card-background-color);

  padding-top: 59px;
  padding-bottom: 58px;
  padding-left: 16px;
  padding-right: 16px;
}
