.ThemeSwitchItem .container {
  display: flex;
}

.ui.menu .ThemeSwitchItem .item {
  padding: 10px 8px 4px 12px;
}

.ThemeSwitchItem .container .label {
  flex: 1;
  align-items: center;
  margin-bottom: 0;
}

.ThemeSwitchItem .container .label,
.ThemeSwitchItem .container .ui.toggle.checkbox.pluggy {
  display: flex;
  align-items: baseline;
}

.ThemeSwitchItem .container .ui.toggle.checkbox.pluggy {
  width: 36px;
  height: 20px;
}
.ThemeSwitchItem > .item .container {
  gap: 16px;
}

.MobileMenu .ThemeSwitchItem > .item .container {
  flex-direction: row-reverse;
}

.MobileMenu .ThemeSwitchItem > .item .container .label {
  justify-content: flex-end;
}
