.ItemFieldContainer {
}

.ItemFieldContainer > .data-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--executions-data-container-title-color);
  margin: 0 0 2px 0;
}

.ItemFieldContainer > .data-value {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  margin: 0;
}
