.ui.loading.button.pluggy .content {
  width: 100%;
  position: relative;
  left: unset;
  top: unset;
  transform: unset;
}

.pluggy.ui.button.secondary.disabled {
  opacity: 1 !important;
  border-color: var(--secondary-border-button-disabled);
  background-color: var(--secondary-background-button-disabled);
  color: var(--secondary-text-button-disabled);
}

.pluggy.ui.button.link {
  text-shadow: none !important;
  user-select: all;
}

/* button.text with icon */

button.pluggy.text,
button.pluggy.text i.icon {
  --text-text: var(--pink-300);
  color: var(--text-text);
  text-shadow: none !important;
  opacity: 1 !important;
}

button.pluggy.text.disabled i.icon {
  color: var(--grey-400);
  cursor: not-allowed;
}

button.pluggy.text i.icon {
  margin-right: 16px;
}
