.TeamDetailsSection {
  background: var(--secondary-card-background-color);

  padding: 24px;

  border-radius: 4px;

  display: flex;
  flex-wrap: wrap;
}

.TeamDetailsSection > .ui.form {
  flex: 1;
  margin-top: 8px;
  flex-basis: 300px;
}

.TeamDetailsSection .team-profile {
  display: flex;
  flex-basis: 300px;
  align-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.TeamDetailsSection .team-profile .profile h4.ellipsis {
  width: 196px;
}

.TeamDetailsSection .team-profile .header {
  margin-bottom: 8px;
}

.TeamUpdateForm .pluggy.field .pluggy.ui.labeled.input,
.TeamUpdateForm .pluggy.field .pluggy.ui.labeled.input input,
.TeamUpdateForm .pluggy.field .pluggy.ui.labeled.input label,
.TeamUpdateForm .pluggy.field .pluggy.ui.labeled.input label .floating-label,
.TeamUpdateForm .pluggy.field.disabled .pluggy.ui.labeled.input,
.TeamUpdateForm .pluggy.field.disabled .pluggy.ui.labeled.input input,
.TeamUpdateForm .pluggy.field.disabled .pluggy.ui.labeled.input label,
.TeamUpdateForm
  .pluggy.field.disabled
  .pluggy.ui.labeled.input
  label
  .floating-label,
.TeamUpdateForm .pluggy.field.disabled .pluggy.ui.labeled.input.filled input,
.TeamUpdateForm
  .pluggy.field.disabled
  .pluggy.ui.labeled.input.filled
  input
  ~ .floating-label {
  background-color: var(--secondary-card-background-color) !important;
}
