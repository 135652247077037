.ApplicationEmptyPlaceholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  border-radius: 4px;
  /* custom dashed border - generated by: https://kovart.github.io/dashed-border-generator/ */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23A0A0A0FF' stroke-width='1' stroke-dasharray='4 3' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  background-color: var(--primary-card-background-color);

  padding-top: 59px;
  padding-bottom: 58px;
  padding-left: 16px;
  padding-right: 16px;
}
