.ui.dropdown,
.ui.simple.active.dropdown,
.ui.simple.dropdown:hover,
.ui.dropdown .menu,
.ui.menu .dropdown.item .menu {
  /* Using !important to override Semantic !important values */
  border-radius: 4px !important;
}

.ui.dropdown .menu .item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.ui.dropdown .menu .item:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ui.dropdown .menu {
  color: var(--text);
}

.ui.dropdown .menu,
.ui.menu .dropdown.item .menu {
  background-color: var(--primary-card-background-color);
}

.ui.dropdown .menu > .item,
.ui.menu .ui.dropdown .menu > .item,
.ui.menu .ui.dropdown .menu > .item.selected {
  /* Using important to override the !important in SemanticUI */
  color: var(--text) !important;
  padding-top: 13px !important;
  padding-left: 16px !important;
  padding-bottom: 13px !important;
  font-size: 14px !important;
}

.ui.dropdown .menu .item.selected,
.ui.dropdown .menu > .item:hover,
.ui.menu .ui.dropdown .menu > .item:hover {
  /* Using important to override the !important in SemanticUI */
  color: var(--text) !important;
}

.ui.dropdown .menu > .header {
  color: var(--text-tertiary);
}

.ui.disabled.dropdown {
  /* Using important to override the !important in SemanticUI */
  cursor: not-allowed;
  pointer-events: all !important;
}

.ui.menu .dropdown.item:hover {
  background-color: inherit;
}
