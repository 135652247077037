.pluggy.alert .text {
  margin-right: 8px;
  margin-left: 16px;
}

.pluggy.alert.medium {
  padding-left: 15px;
}
.pluggy.alert.medium .text {
  margin-left: 11px;
}
