.CustomizationPage {
  margin-top: 24px;
  padding-bottom: 40px;
}

.CustomizationPage .customization-alert {
  /* note: using important to override pluggy-ui component */
  margin-bottom: 24px !important;
}

.CustomizationPage .customization-top-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.CustomizationPage .description {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 24px;
}

.CustomizationPage .customization-top-section .customization-header {
  display: flex;
  align-items: baseline;
}

.CustomizationPage .customization-top-section .customization-header a {
  font-size: 14px;
}

.CustomizationPage .customization-actions .ui.button.secondary {
  margin-right: 24px;
}

.CustomizationPage .ui.header {
  margin-bottom: 12px;
}

.CustomizationPage h4.ui.header {
  font-family: var(--header-font-family);
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  margin-right: 14px;
}

.CustomizationPage .customization-column p {
  font-size: 14px;
  line-height: 16px;
}

.CustomizationPage .connectors-list-title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.CustomizationPage .connectors-list-title > .Tag.pluggy {
  margin-top: 4px;
  margin-bottom: 4px;
}

.CustomizationPage .empty-connectors-warning-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  min-width: 230px; /* width tested manually to avoid text wrap to more than 2 lines */
}

.CustomizationPage .empty-connectors-warning.pluggy.alert.warning {
  width: unset;
  background-color: unset;
  margin-right: 16px;
  margin-top: 0;
  margin-bottom: 0;
}

.CustomizationPage .empty-connectors-warning.pluggy.alert.warning > .indicator {
  display: none;
}
