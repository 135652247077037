.TeamMembersSection .ui.header {
  margin-bottom: 32px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.TeamMembersSection .ui.header button.link:hover:not(.disabled) .content {
  text-decoration: underline;
}

.TeamMembersSection .TeamMembersEmailsInput .team-role-input.ui.dropdown {
  margin-left: 0;
}

.TeamMembersSection .TeamMembersEmailsInput .team-role-input {
  margin-bottom: 10px;
}

.TeamMembersSection .member-profile {
  display: flex;
  align-items: center;
}

.TeamMembersSection .member-profile > .avatar.ui.oval {
  margin-right: 16px;
}

.TeamMembersSection .member-profile > .avatar.ui.oval.blank {
  background-image: var(--background);
  border: 1px dashed var(--grey-300);
}

.TeamMembersSection .ui.table thead tr:first-child > th:first-child {
  border-radius: 4px 0 0 4px;
}

.TeamMembersSection .ui.table thead tr:first-child > th:last-child {
  border-radius: 0 4px 4px 0;
}

.TeamMembersSection .ui.table thead tr th,
.TeamMembersSection .ui.table tbody tr td {
  padding-left: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.TeamMembersSection .ui.table thead th {
  font-weight: var(--font-weight);
  color: var(--text);
  margin-bottom: 16px;
}

.TeamMembersSection .ui.table {
  border: 0;
  background: var(--background);
  color: var(--text);
}

.TeamMembersSection .ui.table thead tr,
.TeamMembersSection .ui.table thead tr th {
  background-color: var(--secondary-card-background-color);
  border: 0;
}

.TeamMembersSection .ui.table tbody tr:first-child td {
  padding-top: 32px;
  padding-bottom: 16px;
}

.TeamMembersSection .ui.table tbody tr:not(:first-child) td {
  padding-top: 16px;
  padding-bottom: 16px;
}

.TeamMembersSection .ui.table tr td {
  border-color: var(--grey-100);
}

.TeamMembersSection .ui .button .content {
  display: flex;
}

.TeamMembersSection .ui .button svg {
  margin-right: 10px;
}

.TeamMembersSection .ui .button .DeleteIcon path {
  fill: var(--primary);
}

.TeamMembersSection .ui .button.disabled .DeleteIcon path {
  fill: var(--grey-400);
}

.TeamMembersSection .member-actions {
  display: flex;
  justify-content: flex-end;
}

.TeamMembersSection .member-actions .ui.button {
  white-space: nowrap;
}

.TeamMembersSection .member-actions .ui.button:not(:first-child) {
  margin-left: 20px;
}

@media only screen and (max-width: 767px) {
  .TeamMembersSection .ui.table:not(.unstackable) tr > td.member-actions {
    /* needed to override Semantic: .ui.table:not(.unstackable) tr>td { display } prop */
    display: flex !important;
  }

  .TeamMembersSection .member-actions .ui.button:not(:first-child) {
    margin-left: 10px;
  }

  .TeamMembersSection .TeamMembersEmailsInput .team-role-input.ui.dropdown {
    width: auto;
  }
}
