.ActionNeededContainer .wrapper-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.ActionNeededContainer .logout-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
  padding-top: 24px;
}

.ActionNeededContainer .terms-and-conditions {
  font-size: 12px;
}

.ActionNeededContainer .title {
  margin-bottom: 4px;
}

.ActionNeededContainer .subtitle {
  font-size: 14px;
}

.ActionNeededContainer .pluggy-logo {
  width: 120px;
}

.ActionNeededContainer .main-container {
  width: 612px;
  max-width: calc(100% - 32px);
}

@media only screen and (min-width: 645px) {
  .ActionNeededContainer .logout-container {
    /* the container of the logout button has position absolute
        to center the form in respect to all the screen. */
    position: absolute;
    padding-right: 70px;
    padding-top: 54px;
  }

  .ActionNeededContainer .wrapper-container {
    align-items: center;
  }
}
