.ApplicationItem.ui.card {
  background-color: var(--primary-card-background-color);
  border: 1px solid var(--primary-card-border-color);
  box-shadow: var(--primary-card-box-shadow);

  --card-padding: 16px;
  padding: var(--card-padding);
}

.ApplicationItem .ui.form .ui.input,
.ApplicationItem .ui.form .ui.input input {
  border: 0;
}

.ApplicationItem.ui.card.environment-demo {
  padding: 4px 8px;
  background-color: var(--secondary-card-background-color);
  border: none;
  box-shadow: none;
}

.ApplicationItem.ui.card.environment-production {
  /* leave empty bottom padding space for production applications, for the rest
   we show the preview link. Note: 30px is the current calculated height of the link.
    In a future, we might replace this empty space with some other content/buttons */
  padding-bottom: calc(var(--card-padding) + 30px);
}

.ApplicationItem .ArrowLink a {
  justify-content: flex-end;
}

.ApplicationItem .bottom-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.ApplicationItem .bottom-section p {
  font-size: 14px;
}

.ApplicationItem .pluggy.field .pluggy.ui.labeled.input,
.ApplicationItem .pluggy.field .pluggy.ui.labeled.input input,
.ApplicationItem .pluggy.field .pluggy.ui.labeled.input label,
.ApplicationItem .pluggy.field .pluggy.ui.labeled.input label .floating-label,
.ApplicationItem .pluggy.field .pluggy.ui.labeled.input label,
.ApplicationItem .pluggy.field.disabled .pluggy.ui.labeled.input.filled input,
.ApplicationItem
  .pluggy.field.disabled
  .pluggy.ui.labeled.input.filled
  input
  ~ .floating-label,
.ApplicationItem
  .pluggy.field.disabled
  .pluggy.ui.labeled.input.filled
  input:focus {
  background-color: var(--primary-card-background-color) !important;
}
