.TeamSubscriptionDetailSection {
  background: var(--secondary-card-background-color);
  padding-top: 20px;
  padding-right: 12px;
  padding-left: 20px;
  padding-bottom: 20px;
  border-radius: 4px;
}

.TeamSubscriptionDetailSection .heading {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 8px;
}

.TeamSubscriptionDetailSection .callToUpgrade {
  font-weight: 600;
}

.TeamSubscriptionDetailSection .subscription-text {
  margin-left: 4px;
}

.TeamSubscriptionDetailSection .subscription-text,
.TeamSubscriptionDetailSection .subscription-text * {
  font-size: 14px;
  line-height: 16px;
}

.TeamSubscriptionDetailSection .pro.subscription-text * {
  margin-top: 12px;
}

.TeamSubscriptionDetailSection .description {
  max-width: 815px;
}

.TeamSubscriptionDetailSection .description .free p {
  margin-bottom: 20px;
}
