.WebhookEventsFilters {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.WebhookEventsFilters > .field.pluggy.date-input,
.WebhookEventsFilters > .field.pluggy.events-input {
  flex: 1;
}

.WebhookEventsFilters
  > .field.pluggy.date-input
  > .DatePicker.pluggy.date-input,
.WebhookEventsFilters
  > .field.pluggy.events-input
  .Dropdown.pluggy.events-input {
  width: 257px;
}

/* when filters don't fit, they're stacked vertically */
@media only screen and (max-width: 1119px) {
  .WebhookEventsFilters {
    flex-direction: column;
    gap: 0;
  }
  .WebhookEventsFilters > .field.pluggy.date-input {
    width: 100%;
  }
  .WebhookEventsFilters > .field.pluggy.events-input {
    width: 100%;
  }

  .WebhookEventsFilters
    > .field.pluggy.date-input
    > .DatePicker.pluggy.date-input,
  .WebhookEventsFilters
    > .field.pluggy.events-input
    .Dropdown.pluggy.events-input {
    width: 100%;
  }

  .WebhookEventsFilters {
    padding-bottom: 16px;
  }
}
