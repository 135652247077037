.WelcomeWidget {
  position: relative;
  background-color: var(--secondary-card-background-color);
  margin: 28px 0;
  padding: 0 20px;
  border-left: 10px solid var(--welcome-widget-alert-accent-color);
  border-radius: 4px;

  font-size: 14px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.WelcomeWidget .section.welcome {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.WelcomeWidget .section.welcome .title {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 5px;
}

.WelcomeWidget .links {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 20px;
  flex-wrap: wrap;
}

.WelcomeWidget .links > div {
  min-width: 200px;
  margin-bottom: 20px;
  margin-right: 60px;
  flex: 1;
}

.WelcomeWidget .links > div:first-child {
  margin-right: 80px;
}

.WelcomeWidget .section.welcome .emoji {
  margin-top: 5px;
  font-size: 21px;
}

.WelcomeWidget .steps-dismiss-action {
  position: absolute;
  display: flex;
  align-items: flex-start;
  top: 24px;
  right: 24px;
}
