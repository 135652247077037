.WebhookInput {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: nowrap;
}

.WebhookInput .field.pluggy.url-input {
  width: 500px;
  padding-top: 16px;
}

.WebhookInput .field.pluggy.events-input {
  width: 215px;
  padding-top: 16px;
}

.WebhookInput .ui.toggle.pluggy.enable-webhook {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.WebhookInput .ui.button.delete-webhook {
  display: flex;
  white-space: nowrap;
}

.WebhookInput .ui.button.delete-webhook > .content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.WebhookInput .ui.button.delete-webhook .DeleteIcon:not(.disabled) path {
  fill: var(--primary);
}

.WebhookInput .hidden-submit-button {
  display: none;
}

@media only screen and (max-width: 1119px) {
  .WebhookInput {
    flex-wrap: wrap;
  }
}
