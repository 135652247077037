.TeamItem {
  border: 1px solid var(--grey-100);
  border-radius: 4px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.TeamItem .team-picture {
  margin: 16px;
  margin-right: 0;
}

.TeamItem .team-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 69px;
  margin: 0 0 0 16px;
}

.TeamItem .ui.button.pluggy.button {
  margin-left: auto;
  margin-right: 20px;
}
