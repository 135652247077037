body.light {
  /* global */
  --danger: var(--red-300);
  --danger-hover: var(--red-500);
  --background: white;
  --background-transparent: var(--grey-900-transparent-08);
  --primary-card-background-color: var(--background);
  --primary-card-border-color: var(--grey-100);
  --primary-card-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  --secondary-card-background-color: var(--grey-50);
  --secondary-card-border-color: var(--grey-200);
  --tertiary-card-background-color: var(--grey-100);
  --divider-color: var(--grey-800);

  /* text */
  --text: var(--grey-900);
  --title-text: var(--grey-900);
  --text-secondary: var(--grey-800);
  --text-tertiary: var(--grey-700);

  /* link */
  --text-link: var(--blue-400);
  --text-link-hover: underline;

  /* icon */
  --icon-active: var(--grey-700);
  --icon-hover: black;
  --icon-disabled: var(--grey-400);
  --input-icon: black;
  --input-icon-hover: var(--grey-800);

  /* navbar */
  --navbar-bottom-border-color: var(--grey-100);
  --navbar-logo-image-vertical-divider-color: var(--grey-500);
  --navbar-active-item-background-color: var(--indigo-100);
  --navbar-profile-options-background-color: var(
    --primary-card-background-color
  );
  --navbar-profile-options-item-background-color: var(--text);
  --navbar-profile-options-item-hover-background-color: var(--grey-50);
  --navbar-mobile-menu-item-hover-background-color: var(--grey-100);
  --navbar-documentation-links-divider: var(--grey-100);

  /* stats */
  --date-picker-shadow: var(--primary-card-box-shadow);
  --stats-border-color: var(--grey-200);
  --stats-trend-positive: var(--green-400);
  --stats-trend-negative: var(--orange-400);
  --stats-footer-background-color: var(--grey-50);
  --stats-trend-arrow-up-icon-color: var(--green-400);
  --stats-trend-arrow-down-icon-color: var(--orange-400);

  /* connectors status */
  --connector-row-divider: var(--grey-300);
  --connector-logo-border-color: var(--grey-100);
  --connector-logo-background-color: var(--primary-card-background-color);

  --status-bar-operational-color: var(--green-500);
  --status-bar-degraded-performance-color: var(--orange-400);
  --status-bar-outage-performance-color: var(--red-400);

  /* no code page */
  --icon-color-fill: #ebe8f1;
  --icon-color-stroke: #271158;

  /* welcome widget */
  --welcome-widget-alert-accent-color: var(--blue-400);

  /* modal */
  --modal-backdrop-color: var(--grey-200-transparent-05);

  /* divider */
  --divider-color: var(--grey-100);

  /* danger zone card */
  --danger-zone-card-border-color: var(--grey-100);
  --danger-zone-card-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

  /* tooltip */
  --tooltip-background-color: var(--white);

  /* accordion */
  --accordion-item-border-color: var(--grey-100);
  --accordion-item-hover-border-color: var(--primary);
  --accordion-item-active-border-color: var(--primary);

  /* divider */
  --divider-color: var(--grey-100);

  /* webhook events table */
  --webhook-events-table-row-error-background-color: var(--red-100);

  /* executions page */
  --executions-container-border-color: var(--grey-100);
  --executions-data-container-title-color: var(--grey-700);

  /* billing page */
  --billing-container-border-color: var(--grey-100);
}
