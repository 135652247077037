.SubscriptionTag {
  /* text color is white in all themes */
  color: white;
}

.SubscriptionTag.sandbox {
  background-color: var(--sandbox-subscription-tag-color);
}

.SubscriptionTag.pro {
  background-color: var(--pro-subscription-tag-color);
}

.SubscriptionTag.trial {
  background-color: var(--trial-subscription-tag-color);
}
