.Stat {
  position: relative;
  border: 1px solid var(--stats-border-color);
  border-radius: 4px;
  min-width: 200px;

  padding: 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.Stat > .stat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Stat > .stat-container > .title {
  font-family: var(--font-family);

  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--text);
  margin-bottom: 16px;
}

.Stat > .stat-container > .value-container {
  display: flex;
  gap: 8px;
}

.Stat > .stat-container > .value-container > .value {
  font-family: var(--header-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: var(--text);
  margin-bottom: 0px;
}

.Stat > .stat-container > .value-container > .trend > .change {
  display: flex;
  gap: 2px;

  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.Stat > .stat-container > .value-container > .trend {
  /* temporairly hiding component until new definitions are made about the values we should show */
  /* TODO: unhide or delete component entirely. tkt: https://pluggy.atlassian.net/browse/RAM-82 */
  display: none;
}

.Stat > .stat-container > .value-container > .trend > .change.positive {
  color: var(--stats-trend-positive);
}

.Stat > .stat-container > .value-container > .trend > .change.negative {
  color: var(--stats-trend-negative);
}

.Stat > .stat-container > .value-container > .trend > .period {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--text-secondary);
}

.Stat .tooltip {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  margin: 11px;
}
