.ui.modal {
  box-shadow: none;
  background-color: var(--background);
  max-width: 600px;
}

.ui.modal,
.ui.modal .header,
.ui.modal .content,
.ui.modal .actions {
  border-radius: 4px;
  border: none;
}

.ui.dimmer {
  background-color: var(--modal-backdrop-color);
}

.ui.modal > .actions {
  border-top: 1px solid var(--grey-light);
}

.ui.modal > .content,
.ui.modal > .actions,
.ui.modal > .header {
  background-color: var(--background);
  color: var(--text);
}

.ui.modal .pluggy.field,
.ui.modal .pluggy.input {
  background-color: var(--background);
}

.ui.modal > i.icon {
  color: var(--white);
  /* Using !important to override Semantic !important values */
  top: 0.5rem !important;
  right: 0.5rem !important;
}

.ui.modal > .Icon.CloseIcon {
  top: 34px !important;
  padding: 0;
  right: 27px !important;
  height: 14px;
  width: 14px;
}

.ui.modal > .header:not(.ui) {
  font-size: 24px;
  line-height: 36px;
}

.ui.modal > .header {
  /* Using !important to override Semantic !important values */
  padding: 24px !important;
  padding-bottom: 0px !important;
}

.ui.modal > .content {
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 14px;
}

.ui.modal > .content > .ui.form {
  margin-top: 22px;
}

.ui.modal > .actions {
  padding: 24px;
}

.ui.modal > .actions > .button {
  margin-left: 24px;
}

.ui.modal > .actions > .button.fluid {
  margin: 0;
}
