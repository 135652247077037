.AllowedUrlsInput .allowed-urls-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 16px;
}

.AllowedUrlsInput .allowed-urls-header > span {
  line-height: 24px;
  margin: 0;
}

.AllowedUrlsInput .DeleteIcon {
  margin-left: -30px;
  margin-top: 10px;
}

.AllowedUrlsInput .DeleteIcon path {
  fill: var(--input-icon);
}

.AllowedUrlsInput .DeleteIcon:hover path {
  fill: var(--input-icon-hover);
}
