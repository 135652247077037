.CustomDashboard .title {
  font-family: var(--header-font-family);
  font-size: 32px;
  font-weight: 400;
  line-height: 30px;
  margin-right: 14px;
  margin-bottom: 20px;
}

.CustomDashboard .iframe-container {
  width: 100%;
  min-height: 800px;
  height: 100%;
  position: relative;
  border: none;
}
