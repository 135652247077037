.ChevronRightIcon {
  cursor: pointer;
}

.ChevronRightIcon.disabled {
  cursor: not-allowed;
}

.ChevronRightIcon.disabled path {
  fill: var(--grey-400);
}
