.Report {
  border: 1px solid var(--stats-border-color);
  border-radius: 4px;
  padding-top: 20px;
}

.Report > .title {
  margin-bottom: 16px;
  font-family: var(--header-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin-left: 20px;
}

.Report .report-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 80px;
  padding: 0 20px;
  flex-wrap: wrap;
}

.Report .data .total-items {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
  gap: 8px;
}

.Report .data .total-items-value {
  font-weight: 600;
  font-size: 24px;
  vertical-align: center;
}

.Report .data .total-items-label {
  font-weight: 400;
  font-size: 16px;
  vertical-align: center;
}

.Report .footer {
  background-color: var(--stats-footer-background-color);
  height: 36px;
  margin-top: 24px;
  display: flex;
}

.Report .footer .ui.button.pluggy.show-details {
  display: flex;
  margin-left: auto;
  margin-right: 24px;
  align-items: center;
}

.Report .footer .ui.button.pluggy.show-details .content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.Report .footer .ui.button.pluggy.show-details .Icon.ChevronDownIcon > path,
.Report .footer .ui.button.pluggy.show-details .Icon.ChevronUpIcon > path {
  fill: var(--link);
}

.Report
  .footer
  .ui.button.pluggy.show-details
  .Icon.ChevronDownIcon.disabled
  > path,
.Report
  .footer
  .ui.button.pluggy.show-details
  .Icon.ChevronUpIcon.disabled
  > path {
  fill: var(--icon-disabled);
}

.Report .details {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
}
.Report .details .detail {
  flex: 1;
  padding: 20px;
}

.Report .details .detail:not(:last-child) {
  border-right: 1px solid var(--divider-color);
}

.Report .details .detail .LegendItem .percentage {
  margin-left: 0;
  font-weight: 600;
}

.Report .legend {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.Report .details .detail .LegendItem {
  width: unset;
  margin-bottom: 30px;
  font-size: 14px;
}

.Report .details .detail .detail-entry {
  display: flex;
  gap: 16px;
  width: 100%;
  margin-bottom: 16px;
  font-size: 14px;
  max-width: 280px;
}

.Report .details .detail .detail-entry .value {
  margin-left: auto;
}

.Report .EmptyChartMessage {
  margin-left: 20px;
  margin-right: 20px;
}

@media only screen and (max-width: 1119px) {
  .Report .details {
    flex-direction: column;
  }

  .Report .details .detail {
    width: unset;
    max-width: unset;
  }

  .Report .details .detail:not(:last-child) {
    border-right: none;
    border-bottom: 1px solid var(--divider-color);
  }
}
