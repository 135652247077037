.ui.menu > .ui.container,
.ui.container {
  width: calc(100% - 2 * var(--container-side-margin)) !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

:root {
  --container-side-margin: 72px;
}

@media only screen and (max-width: 1600px) {
  :root {
    --container-side-margin: 54px;
  }
}

@media only screen and (max-width: 1280px) {
  :root {
    --container-side-margin: 36px;
  }
}

@media only screen and (max-width: 767px) {
  :root {
    --container-side-margin: 18px;
  }
}
