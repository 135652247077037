.TeamDangerZone {
  padding-top: 16px;
}

.TeamDangerZone .pluggy.card {
  font-size: 14px;
  border-width: 1px;
  border-color: var(--danger-zone-card-border-color);
  box-shadow: var(--danger-zone-card-shadow);
  border-radius: 4px;
  border-style: solid;
  padding: 20px;
}

.TeamDangerZone .pluggy.card .ui.divider {
  width: calc(100% + 2 * 20px);
  left: 50%;
  right: 50%;
  margin-left: -20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.TeamDangerZone .pluggy.card .action-buttons {
  display: flex;
  justify-content: flex-end;
}

.TeamDangerZone .pluggy.card > .content {
  padding: 20px;
}

.TeamDangerZone .pluggy.card > .content > .header {
  font-size: 18px;
}

.TeamDangerZone .widgets-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.TeamDangerZone .widgets-section > div {
  flex: 1;
  flex-basis: 350px;
  margin-bottom: 18px;
}

.TeamDangerZone .action-buttons .pluggy.button.primary:not(.disabled) {
  background-color: var(--danger);
}

.TeamDangerZone .action-buttons .pluggy.button.primary:not(.disabled):hover {
  background-color: var(--danger-hover);
}
